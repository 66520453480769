import { JUSTIFICATIONS } from "@/components/fields/Field/FieldSettings/util.js";
export default {
  attributes: [
    {
      mode: "table",
      name: "table.header.behavior_color",
      hint: "Remove background color from header",
      type: "select",
      value: "none",
      options: ["none"],
    },
    {
      name: "table.mobile-breakpoint",
      mode: "table",
      hint: "Width (in pixels) at which to start displaying the mobile layout of a table",
      type: "number",
      value: 600,
    },
    {
      name: "table.fixed-header",
      mode: "table",
      hint: "Fixed header (does not scroll)",
      type: "switch",
      value: true,
    },
    {
      name: "table.footer-props.items-per-page-options",
      mode: "table",
      hint: "Control the number of records to display. The first one in the list will be used by default",
      type: "multi_select",
      value: [5, 10, 15],
      options: [5, 10, 15],
    },
    {
      name: "slider.width",
      mode: "slider",
      type: "number",
      value: 100,
    },
    {
      name: "slider.center-active",
      mode: "slider",
      hint: "Should the center element be highlighted",
      type: "switch",
      value: true,
    },
    {
      name: "slider.show-arrows",
      mode: "slider",
      type: "select",
      hint: "When to show slider",
      value: "always",
      options: ["always", "desktop", "true", "false"],
    },
    {
      name: "slider.active-class",
      mode: "slider",
      type: "text",
      value: "behavior_current_item",
    },
    {
      name: "cards.justify",
      mode: "cards",
      type: "select",
      hint: "Decide how cards are justified",
      value: "justify-start",
      options: JUSTIFICATIONS,
    },
    {
      name: "cards.width",
      mode: "cards",
      hint: "Width to take up. Range: 1-12",
      type: "text",
      value: "4",
    },
    {
      name: "cards.layout.name",
      mode: "cards",
      type: "select",
      hint: "These represent some views that were developed rather than configured",
      value: "",
      options: ["contact_card", "profile", "subscription_plan", "product_card"],
    },
  ],
  classes: ["px-2"],
};
