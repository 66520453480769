<template>
  <EditorWrapper
    aut-form
    :definition="definition"
    :context="context"
    :is_design_mode="isDesignMode"
    class="relative"
    @update:definition="$emit('refresh_content', $event)"
    :actions="editorActions"
    :class="`behaviour_form ${isDesignMode ? 'designMode pa-1' : ''}`"
    @edit_form="displayEditDialog('default')"
    @remove_form="$emit('remove')"
    @edit_permissions="permissionDialogToggle = true"
  >
    <EditFormDialog
      v-if="displayEditDialogFlag"
      :definition.sync="definition"
      :tab="editDialogTab"
      @update:definition="$emit('refresh_content', $event)"
      @close="displayEditDialogFlag = false"
    />
    <PermissionEditor
      v-if="permissionDialogToggle"
      :context="context"
      mode="component"
      :definition.sync="definition"
      @close="permissionDialogToggle = false"
    />
    <CloneFieldDialog
      v-if="fieldToClone"
      :field="fieldToClone"
      :type="typeToClone"
      :definition.sync="definition"
      @close="fieldToClone = null"
      @update:definition="$emit('refresh_content', $event)"
    />
    <EditableField
      aut-form-title
      class="behaviour_component_title"
      v-if="displayTitle"
      :design="isDesignMode"
      :text.sync="definition.title"
      :definition="{
        label: 'Form Title',
        hint: 'Optional. Leave blank to skip',
      }"
      @update:text="updateNode('title', $event)"
    >
      <ContentTitle
        :titleClass="titleClass"
        :title="effectiveTitle"
        aut-form-title-value
      />
    </EditableField>
    <v-form v-model="valid" ref="form" :key="formKey">
      <form-fields
        aut-form-fields
        :is_design="isDesignMode"
        :fields="formFields"
        @update="updateOrder"
        classes="row row--dense behavior_form_fields"
      >
        <v-col
          v-for="(field, index) in formFields"
          class="behavior_form_field"
          :class="getStylingClasses(field)"
          :ref="field.name"
          :cols="getColumnWidth(field)"
          :key="`${field.name}-${index}`"
        >
          <FormField :field="field">
            <Field
              :path="getPath(field)"
              :definition="field"
              :context="context"
              @remove_field="removeField(index)"
              @copy_field="copyField(field)"
              @update_field="editField($event, index)"
              @trigger-submit="submitForm"
              @hide_field="hideField(field)"
              @show_field="showField(field)"
              @update_field_permissions="updateFieldPermissions($event, index)"
            />
          </FormField>
        </v-col>
      </form-fields>
      <AddFieldDialog
        v-if="isDesignMode"
        @add="addField"
        :context="context"
        :filter="FIELDS_FILTER_FORM"
        :definition="definition"
      />
      <v-row
        v-if="showActionsBar"
        aut-form-actions
        class="ma-2"
        :key="definitionUpdated"
      >
        <v-col
          cols="12"
          class="d-md-flex"
          :class="`${isDesignMode ? 'designMode' : ''} mt-2 pa-2`"
        >
          <form-fields
            :is_design="isDesignMode"
            :fields="actions"
            @update="updateActionsOrder"
            :classes="`${actionOrientation}`"
            class="behavior-form-actions pb-2"
          >
            <Field
              class="mx-lg-1 mx-2 my-2 my-sm-0"
              v-for="(action, i) in actions"
              :key="`action-${definitionUpdated - i}`"
              :definition="action"
              :context="context"
              @remove_field="removeAction(action, i)"
              @copy_field="copyAction(action, i)"
              @update_field="updateAction($event, i)"
              @trigger-submit="submitForm"
              @update_field_permissions="updateActionPermissions(action, i)"
            />
          </form-fields>
          <div v-if="isDesignMode" class="ml-4">
            <EditorAction
              :action="actionAction"
              @add_action="displayEditDialog('actions')"
            />
          </div>
        </v-col>
      </v-row>
    </v-form>
  </EditorWrapper>
</template>

<script>
import { componentMixin, componentDesignerMixin } from "@/components/mixin.js";
import { getWidth, FIELDS_FILTER_FORM } from "@/components/fields/util.js";
import { uniqueID } from "@/util";
import EditorWrapper from "@/components/editor/EditorWrapper";
import FormField from "./FormField.vue";
import FormFields from "./FormFields.vue";
import ContentTitle from "../ContentTitle.vue";
import permissionsMixin from "@/components/permissionsMixin.js";

const debug = require("debug")("atman.components.form");

const controls = [
  {
    id: "configure-form",
    label: "Edit Form",
    icon: "mdi-pencil",
    event: "edit_form",
    param: "",
  },
  {
    id: "delete-form",
    label: "Remove Form",
    confirmation: "Are you sure you want to continue?",
    icon: "mdi-delete",
    event: "remove_form",
    param: "",
  },
  {
    id: "edit_permissions",
    label: "Change Permissions",
    icon: "mdi-key",
    event: "edit_permissions",
    param: "",
  },
];

export default {
  name: "Form",
  mixins: [componentMixin, componentDesignerMixin, permissionsMixin],
  components: {
    EditorWrapper,
    Field: () => import("@/components/fields/Field"),
    EditFormDialog: () =>
      import("@/components/pageContent/Form/EditFormDialog"),
    FormField,
    FormFields,
    ContentTitle,
  },
  data() {
    return {
      formKey: 0,
      valid: false,
      componentStore: {},
      displayEditDialogFlag: false,
      editDialogTab: "default",
    };
  },
  watch: {
    valid() {
      // This will force the submit buttons (and others for now) to get re-rendered
      this.definitionUpdated++;
    },
  },
  computed: {
    isDialog() {
      let result = false;
      const pageDialog = document.querySelector("[aut-page-dialog]");
      if (pageDialog && pageDialog.contains(this.$el)) {
        result = true;
      }
      return result;
    },
    titleClass() {
      let classes = this.definition?.titleClasses || [];

      let result = classes.join(" ");

      return result;
    },
    showActionsBar() {
      return this.actions.length || this.isDesignMode;
    },
    actionOrientation() {
      let result = "";
      if (this.isSmallScreen) {
        result = "";
      }
      if (this.isTabletOrLarger) {
        result += "row row--dense";
        const justification = this.getFeatureValue(
          "form.actions.justification",
          "Right"
        );
        switch (justification) {
          case "Right":
            result += " justify-end";
            break;
          case "Center":
            result += " justify-center";
            break;
          case "Left":
            result += " justify-start";
            break;
          default: {
            result += " justify-end";
          }
        }
      }
      debug({ result });
      return result;
    },
    editorActions() {
      return [...controls];
    },
    displaySubmitButton() {
      return (
        this.submitButton &&
        this?.definition?.definition?.mode != "display" &&
        this?.definition?.definition?.mode != "readonly"
      );
    },
    submitButton() {
      const component = this;
      const result = component?.definition?.apis?.submit;
      if (
        !!component?.definition?.apis?.submit?.url &&
        component?.definition?.apis?.submit?.type
      ) {
        return result;
      }
      return false;
    },
    actions: {
      get() {
        const component = this;
        const result = (component?.definition?.definition?.actions || [])
          .map((field) => {
            let mode = field.mode;
            if (typeof component.definition.mode != "undefined") {
              mode = component.definition.mode;
            }
            field.type = "action";
            field.mode = mode;
            return field;
          })
          .filter(({ _ignore_in_page_editor_ }) => !_ignore_in_page_editor_);
        const hasCancel = !!result.find((field) => field.name == "cancel");
        if (this.displaySubmitButton) {
          let disableButton = this.isFeatureEnabled(
            "form.submit_button.disable_when_invalid",
            false
          );
          if (!hasCancel) {
            result.unshift({
              name: "cancel",
              label: "Cancel",
              display: {
                attributes: {
                  color: "secondary",
                },
                classes: ["behavior_atman_button"],
              },
              _ignore_in_page_editor_: true,
              type: "action",
              value: {
                type: "_cancel_",
              },
            });
          }
          const submitButtonAttributes = this.submitButton?.display
            ?.attributes || {
            color: "primary",
          };
          const submitButtonDefinition = {
            name: "submit",
            _permissions: this.submitButton._permissions,
            label: this.submitButton.label || "Submit",
            disabled: disableButton ? !this.valid : false,
            display: {
              attributes: submitButtonAttributes,
              classes: ["mr-2", "mt-lg-0", "mt-2", "behavior_atman_button"],
            },
            _ignore_in_page_editor_: true,
            type: "action",
            submit: true,
            value: this.submitButton,
          };
          debug(`submitButton definition`, submitButtonDefinition);
          result.push(submitButtonDefinition);
        }
        debug(`derived actions in form`, result);
        return result.map((item) => {
          item.id = item.id || uniqueID();
          return item;
        });
      },
      set(value) {
        this.updateActionsOrder(value);
      },
    },
  },
  created() {
    this.getWidth = getWidth;
    this.FIELDS_FILTER_FORM = FIELDS_FILTER_FORM;
    this.actionAction = {
      id: "add-action",
      label: "Add Action",
      icon: "mdi-plus",
      event: "add_action",
    };
  },
  mounted() {
    debug("In mounted of Form", this.context);
    this.determineTitleDisplay();
    this.bypassDefaultFormSubmission();
    this.fetchData();
  },
  methods: {
    bypassDefaultFormSubmission() {
      const formComponent = this.$refs.form;
      if (formComponent && formComponent.$el) {
        formComponent.$el.onsubmit = function () {
          return false;
        };
      }
    },
    markEventAsDone(eventID) {
      if (!eventID) {
        return;
      }
      this.$store.dispatch(`${this.context}/triggerAction`, {
        actionDefinition: {
          value: {
            type: "event",
            name: eventID,
          },
        },
      });
    },
    async submitForm(options = {}) {
      const component = this;
      const methodDebug = debug.extend("submitForm"); // eslint-disable-line

      const { definition, eventID } = options || {};

      component.$refs.form.validate();
      if (!component.valid) {
        component.markEventAsDone(eventID);
        return;
      }
      // RESET state if explicitly configured or if this is a creation page (i.e. no data)
      const resetState =
        definition?.value?.reset_state ||
        (!component.definition?.apis?.data &&
          definition?.value?.reset_state != false);
      let payload = {
        actionDefinition: definition,
        isDialog: component.isDialog,
        resetState,
      };
      methodDebug(`payload`, payload);
      try {
        await component.$store.dispatch(
          `${component.context}/triggerAction`,
          payload
        );
        if (resetState) {
          methodDebug(`resetting form`);
          component.$refs.form && component.$refs.form.reset();
          /* Explicitly re-rendering the form. We have many non-standard fields which don't 
          respect the form reset */
          component.formKey++;
        }
      } catch (e) {
        console.error(`error during form submission`, e);
        let message = `Operation unsuccessful${
          typeof e == "string" ? ": " + e : ""
        }`;
        component.displayErrorMessage(message);
      } finally {
        payload = null;
        component.markEventAsDone(eventID);
      }
    },
    displayEditDialog(tab) {
      debug(`in method: displayEditDialog`, tab);
      this.editDialogTab = tab;
      this.displayEditDialogFlag = true;
    },
    getPath(field) {
      return field.is_container ? `` : `${field.name}`;
    },
    getStylingClasses(field) {
      let result = [];
      if (field?.display?.attributes?.behavior_centered === true) {
        result.push("behavior_centered");
      }
      const classes = field?.display?.classes || [];
      if (Array.isArray(classes)) {
        result = [...result, ...classes];
      }
      debug(`stylingClasses`, field, result);
      return result.join(" ");
    },
    isBlockMode(field) {
      return field?.display?.block;
    },
    getColumnWidth(field) {
      let result;
      if (this.isSmallScreen || this.isBlockMode(field)) {
        result = 12;
      } else {
        result = getWidth(field);
      }
      debug(`Returning [${result}] as width for ${field.name}`);
      return result;
    },
    hideField(field) {
      if (this.isDesignMode) {
        return;
      }
      const classList = this.$refs[field.name][0].classList;
      classList.add("hiddenInPage");
    },
    showField(field) {
      if (this.isDesignMode) {
        return;
      }
      const classList = this.$refs[field.name][0].classList;
      classList.remove("hiddenInPage");
    },
  },
};
</script>
<style lang="scss" scoped>
.behaviour_form {
  display: relative;
}
.behavior_form_fields {
  padding: 8px;
  .behavior_form_field {
    padding: 0 8px;
  }
}
</style>
