const debug = require("debug")("atman.components.list.mixin"); // eslint-disable-line
import { clone } from "@/util";

import { searchUtil } from "@/components/pageContent/List/util.js";

const getPaginatedResults = (component) => {
  let resultsToRender = [];
  const results = component.results;
  resultsToRender = component.pagination.count
    ? (results || []).slice(0, component.index)
    : results;
  debug(`getPaginated Results`, resultsToRender.length, resultsToRender);
  component.displayResults(resultsToRender);
};

const resetPaginatedResults = (component) => {
  // In client mode, remove everything on screen and add new records
  let paginatedResults = [];

  component.$nextTick(() => {
    component.index = component.pagination.count;
    debug(`in client resetPaginatedResults`);
    component.$set(component, "paginatedResults", paginatedResults);
    getPaginatedResults(component);
  });
};

const moreAvailable = (component) => {
  const result = component.results.length > component.paginatedResults.length;
  debug(`moreAvailable: client: ${result}`);
  return result;
};

const loadNextPage = (component) => {
  if (!moreAvailable(component)) {
    debug(`Ignoring pagination trigger`);
    return;
  }

  debug(
    `loadNextPage triggered. Will get next ${component.pagination.count} results. Total: ${component.paginatedResults.length}`
  );

  const newIndex = component.index + component.pagination.count;
  const newResults = (component.results || []).slice(component.index, newIndex);
  component.index = newIndex;
  debug(
    `new index: [${newIndex}]. index: [${component.index}], results`,
    newResults
  );
  debug(`attempting to display next page`);
  component.displayResults(newResults);
};

const loadPrevPage = (component) => {
  debug(
    `loadPrevPage triggered. Will get prev ${component.pagination.count} results. Total: ${component.paginatedResults.length}`
  );
  let newIndex = component.index - component.pagination.count;
  newIndex = newIndex < 0 ? 0 : newIndex;
  const newResults = (component.results || []).slice(newIndex, component.index);
  component.index = newIndex;
  debug(`attempting to display prev page`);
  component.displayResults(newResults);
};

const handleSearch = (component) => {
  component.$set(component, "initialised", false);
  // Take the original results
  let result = [...component.results];
  if (component.filterData) {
    result = searchUtil(
      component.filterData,
      result,
      component.effectiveSearchFields
    );
  }

  if (component.searchData) {
    // IF search criteria is available, filter results by that criteria
    result = searchUtil(
      component.searchData,
      result,
      component.effectiveSearchFields
    );
  }

  if (component.alphabeticSearchData) {
    result = searchUtil(
      component.alphabeticSearchData,
      result,
      component.effectiveSearchFields
    );
  }
  component.$set(component, "filteredResults", clone([...(result || [])]));
  component.$set(component, "initialised", true);
};

const getRuntimeQueryParams = (/* component */) => {
  return {};
};

const getEffectiveResults = (component, fetchedData) => {
  return fetchedData;
};

export default {
  resetPaginatedResults,
  loadNextPage,
  loadPrevPage,
  handleSearch,
  getRuntimeQueryParams,
  getEffectiveResults,
};
